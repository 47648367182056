<template>
  <div>
    <div class="d-flex justify-content-center mt-5">
      <img src="@/assets/images/common/sk-pinx-logo.png" />
    </div>

    <div class="d-flex justify-content-center">
      <h5 class="mt-5 mb-5 pl-4 pr-4" align="center" style="line-height: 1.5">
        SK핀크스는 SK 그룹의 HIGH-END RESORT로서<br />
        끊임없는 변화와 도전을 통해 한국을 대표하는 글로벌 리조트로 성장을 계속하고 있습니다.
      </h5>
    </div>
    <img src="@/assets/images/common/introducepinxdetail_bodyimage01.jpg" style="display: block; width: 100%" />

    <div class="hanra-catch d-flex justify-content-center align-content-center">
      <h5 class="pl-4 pr-4" align="center" style="line-height: 2rem">
        한라산 중턱의 넓은 초원, 오름과 바다, 제주만의 아름다운 풍경이 어우러지는 핀크스 리조트는<br />
        고객감동을 위한 가치 창출을 최우선으로 삼아 고객님들에게 편안한 휴식과 힐링의 공간을 제공하고 있습니다.
      </h5>
    </div>

    <div class="container">
      <div class="row d-flex mt-5">
        <div class="col-lg-7" style="padding: 0" align="right">
          <img src="@/assets/images/common/introducepinxdetail_bodyimage02.jpg" style="display: block; width: 100%" />
        </div>
        <div class="col-lg-5 my-auto" style="padding: 0" align="left">
          <div style="padding-left: 30px; padding-right: 20px">
            <h3 class="pt-lg-0 pt-4" style="color: #c9af85">PINX GOLF CLUB</h3>
            <p>
              세계적인 명문으로 나아가겠다는 목표로 만들어진 핀크스 골프클럽은 1999년 개장부터 신흥 명문이라는 평가를
              받으며 당당히 한국 Best 골프코스 1위와 세계 100대 골프코스에 이름을 올리는 쾌거를 이룩하였습니다.
            </p>
          </div>
        </div>
      </div>

      <div class="row d-flex mt-5">
        <div class="col-lg-5 my-auto order-lg-0 order-1" style="padding: 0" align="right">
          <div style="padding-right: 20px">
            <h3 class="pt-lg-0 pt-4" style="color: #c9af85">PODO HOTEL</h3>
            <p>
              세계적인 건축가 이타미 준이 설계한 HEALING BUTIQUE 포도호텔은 많은 고객들이 꼭 한번 숙박하고 싶어하는
              제주의 대표적인 호텔로 각광받고 있습니다.
            </p>
          </div>
        </div>
        <div class="col-lg-7 order-lg-1 order-0" style="padding: 0" align="center">
          <img src="@/assets/images/common/introducepinxdetail_bodyimage03.jpg" style="display: block; width: 100%" />
        </div>
      </div>

      <div class="row d-flex mt-5 mb-5">
        <div class="col-lg-7" style="padding: 0" align="right">
          <img src="@/assets/images/common/introducepinxdetail_bodyimage04.jpg" style="display: block; width: 100%" />
        </div>
        <div class="col-lg-5 my-auto" style="padding: 0" align="left">
          <div style="padding-left: 30px; padding-right: 20px">
            <h3 class="pt-lg-0 pt-4" style="color: #c9af85">THE ANNEX</h3>
            <p>
              2015년 새롭게 개장한 디아넥스는 43객실을 비롯하여 온천 사우나, 수영장, 컨퍼런스룸 등 다양한 부대시설이
              구비되어 고객 편의와 만족도 높은 휴식을 제공합니다.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="last-catch d-flex justify-content-center align-content-center mb-5">
      <h5 class="pl-4 pr-4" align="center" style="line-height: 2rem">
        핀크스 리조트는 한국의 대표 리조트로서의 명성을 강화하고 최상의 시설 환경과 최고의 서비스로<br />
        고객님께 감동을 드릴 수 있도록 최선을 다해 노력하겠습니다.<br />
        많은 관심과 성원을 부탁드립니다.
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introducepinxdetail',
};
</script>

<style scoped lang="scss">
.hanra-catch {
  height: 300px;
  background-color: #c9af85;
  color: #ffffff;
  align-items: center;
}
.last-catch {
  height: 300px;
  background-color: #e4d7c2;
  color: #524a3d;
  align-items: center;
}
p {
  word-break: keep-all;
}
h5 {
  word-break: keep-all;
}
</style>
