<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="introducepinx_breadcrumb" />
    </div>

    <div class="header">
      <h1 class="overlay">INTRODUCE</h1>
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>회사 소개</strong>
        </h2>
      </div>
    </div>

    <div align="center">
      <div class="row justify-content-center categoryRadioGroup">
        <div id="btn-radios-2" style="width: 100%">
          <label class="cateBoxContainer">
            <input type="radio" value="회사소개" v-model="tab_name" />
            <div class="front-end cateBox">
              <span>회사소개</span>
            </div>
          </label>
          <label class="cateBoxContainer">
            <input type="radio" value="연혁" v-model="tab_name" />
            <div class="back-end cateBox">
              <span>연혁</span>
            </div>
          </label>
        </div>
      </div>
      <div class="my-3">
        <Introducepinxdetail v-if="tab_name === '회사소개'" />
        <Pinxhistory v-if="tab_name === '연혁'" />
      </div>
    </div>
  </div>
</template>

<script>
import Introducepinxdetail from '@/pages/Footerlink/Components/Introducepinxdetail';
import Breadcrumb from '@/components/Breadcrumb';
import Pinxhistory from '@/pages/Footerlink/Components/Pinxhistory';

export default {
  name: 'Introducepinx',
  data() {
    return {
      introducepinx_breadcrumb: {
        toplink: 'HOME',
        prevlink: '회사소개',
        present: '회사소개',
      },
      tab_name: '회사소개',
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '회사소개'});
  },
  components: {Pinxhistory, Breadcrumb, Introducepinxdetail},
};
</script>

<style scoped lang="scss">
.menuSiteRadioGroup {
  height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.categoryRadioGroup {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 3rem;
  margin-top: 1rem;
  border-bottom: 1px solid #e0e0e0;
}
.cateBoxContainer {
  width: 50%;
}
.siteButton {
  height: 100%;
}
.customButton {
  font-size: 2rem;
}

input[type='radio'] {
  display: none;
  &:checked {
    + .siteBox {
      background-color: #343a40;
      span {
        color: white;
        //        transform: translateY(100%);
        &:before {
          //          transform: translateY(100%);
          opacity: 1;
        }
      }
    }
  }
}
input[type='radio'] {
  display: none;
  &:checked {
    + .cateBox {
      background-color: #635e5e;
      span {
        color: white;
        //        transform: translateY(100%);
        &:before {
          //          transform: translateY(100%);
          opacity: 1;
        }
      }
    }
  }
}
.siteBox {
  background-color: white;
  border: 1px solid #e0e0e0;
  height: 4rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 900;
  line-height: 3.6rem;
  span {
    font-size: 0.9rem;
    user-select: none;
    color: #2f2f2f;
    &:before {
      display: block;
      opacity: 0;
      font-weight: normal;
      color: white;
    }
  }
}
.cateBox {
  background-color: white;
  border: 1px solid #e0e0e0;
  //width: 14rem;

  height: 3rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 900;
  line-height: 3rem;
  &:active {
  }
  span {
    font-size: 0.9rem;
    user-select: none;
    color: #2f2f2f;
    &:before {
      display: block;
      opacity: 0;
      font-weight: normal;
      color: white;
    }
  }
}
.front-end {
  width: 100%;
  span {
    &:before {
      content: '';
    }
  }
}
.back-end {
  width: 100%;
  span {
    &:before {
      content: '';
    }
  }
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/introducepinx_headerimage.jpg');
  }
}
@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
}
</style>
